import { Application } from "@hotwired/stimulus"

const application = Application.start()

import Lightbox from 'stimulus-lightbox'
application.register('lightbox', Lightbox)

import { Alert } from "tailwindcss-stimulus-components"
application.register('alert', Alert)

import { Dropzone } from "dropzone"
application.register('dropzone', Dropzone)

// Configure Stimulus development experience
application.debug = false
window.Stimulus   = application

export { application }