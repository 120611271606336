import Carousel from 'stimulus-carousel'
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
export default class extends Carousel {
    connect() {
        super.connect()
        this.swiper
        this.defaultOptions
    }

    // You can set default options in this getter.
    get defaultOptions() {
        return {
            preloadImages: false,
            lazy: {
                enabled: true,
//                checkInView: true,
//                loadOnTransitionStart: true,
//                scrollingElement: 'swiper-container',
            },
            spaceBetween: 0,
//            speed: 500,
            centeredSlides: true,
            loop: false,
//            autoplay: {
//                delay: 1300,
//                disableOnInteraction: true,
//            },
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
//              type: 'progressbar'
            },
//            navigation: {
//                nextEl: `.swiper-button-next-${nextId}`,
//                prevEl: `.swiper-button-prev-${prevId}`,
//            },
//            scrollbar: {
//                el: '.swiper-scrollbar'
//            },
        }
    }
}