import { application } from "./application"

import ResetFormController from "./reset_form_controller"
application.register("reset-form", ResetFormController)

import AutoclickController from "./autoclick_controller"
application.register("autoclick", AutoclickController)

import CharcountController from "./charcount_controller"
application.register("charcount", CharcountController)

import DropzoneController from "./dropzone_controller"
application.register("dropzone", DropzoneController)

import CarouselController from "./carousel_controller"
application.register("carousel", CarouselController)

import Carousel from './carousel_controller.js'
application.register("carousel", Carousel)